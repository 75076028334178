<template>
  <moe-page title="分类列表">
    <moe-inquire @search="activitySearch">
      <el-form-item label="活动编号">
        <el-input :value="activityParams.id" placeholder="输入活动编号" @input="(value) => activityParams.id = $moe_formatter.formatterId(value)" clearable />
      </el-form-item>
      <el-form-item label="活动名称">
        <el-input placeholder="输入活动名称" v-model="activityParams.name" clearable />
      </el-form-item>
      <el-form-item label="活动位置" prop="alias">
        <moe-select type="activityAliasList" v-model="activityParams.alias" placeholder="选择活动位置" clearable></moe-select>
      </el-form-item>
      <el-form-item label="状态">
        <moe-select v-model="activityParams.showStatus" type="mallAdvertStatus" placeholder="选择状态" />
      </el-form-item>
    </moe-inquire>

    <!-- 活动信息 -->
    <moe-table ref="activityTable" url="/shop/activity/queryShopActivityList" :params="activityParams" :number-show="false" row-key="id">
      <!-- 操作按钮 -->
      <template slot="tool">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="$moe_coordinator.navigateTo('/activity/add')">添加活动</el-button>
      </template>
      <el-table-column prop="id" label="活动编号" min-width="80" />
      <el-table-column prop="name" label="活动名称" min-width="130" />
      <el-table-column prop="alias" label="活动位置" min-width="130">
        <template slot-scope="{ row }">
          {{ $moe_format.getActivityAliasList(row.alias) }}
        </template>
      </el-table-column>
      <el-table-column prop="goodsNum" label="最多展示商品数量" min-width="150" />
      <el-table-column prop="content" label="活动介绍" min-width="130" :show-overflow-tooltip="true" />
      <el-table-column prop="sort" label="排序" min-width="130" />
      <el-table-column prop="coverUrl" label="封面图片" width="130">
        <template slot-scope="{ row }">
          <moe-image v-if="row.coverUrl" :src="row.coverUrl" width="70px" height="70px" />
        </template>
      </el-table-column>
      <el-table-column label="创建时间" min-width="150">
        <template slot-scope="{ row }">
          {{ $moe_time.getTimeStamp(row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column label="展示时间" min-width="310">
        <template slot-scope="{ row }">
          <div v-if="row.startTime">
            {{ `${$moe_time.getTimeStamp(row.startTime)} 至 ${$moe_time.getTimeStamp(row.endTime)}` }}
          </div>
          <div v-else>长期</div>
        </template>
      </el-table-column>

      <el-table-column label="状态" width="90">
        <template slot-scope="{ row }">
          <div :class="$moe_format.getMallContentStatusColor(row.showStatus)">
            {{ $moe_format.getMallContentStatus(row.showStatus) }}
          </div>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="400" fixed="right">
        <div class="moe-table__btns" slot-scope="{ row }">
          <el-button type="warning" size="mini" icon="el-icon-edit" @click="$moe_coordinator.navigateTo(`/activity/add`, { id: row.id })">编辑</el-button>
          <el-button type="warning" size="mini" icon="el-icon-edit" @click="$moe_coordinator.navigateTo(`/activity/goods`, { id: row.id })">管理商品</el-button>
          <el-button v-if="['RUNNING'].includes(row.showStatus)" type="warning" size="mini" icon="el-icon-video-pause" @click="handleShelf(row, 2, '暂停')">暂停</el-button>
          <el-button v-if="['STOP'].includes(row.showStatus)" type="info" size="mini" icon="el-icon-video-play" @click="handleShelf(row, 1, '启用')">启用</el-button>
          <el-button type="danger" size="mini" icon="el-icon-delete" @click="activityDelete(row)">删除</el-button>
        </div>
      </el-table-column>
    </moe-table>
  </moe-page>
</template>

<script>
export default {
  name: 'Activity',
  data() {
    return {
      activityParams: {
        pageNum: 1,   //当前页
        pageSize: 10, //每页条数
        id: '',
        name: '',     //活动名称
        alias: '',
        showStatus: '',
      },
    };
  },
  methods: {
    /**
      * 活动搜索
      **/
    activitySearch(isSearch) {
      if (!isSearch) {
        this.activityParams = {
          pageNum: 1,
          pageSize: 10,
          id: '',
          name: '',
          alias: '',
          showStatus: '',
        };
      }

      //刷新商品表格
      this.$refs.activityTable.searchData();
    },
    /**
       * 活动删除
       **/
    activityDelete({ name, id }) {
      this.$moe_layer.confirm(`您确定要删除当前选项"${name}"吗？`, () => {
        this.$moe_api.GOODS_API.activityDelete({ id }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('删除成功');
            this.$refs['activityTable'].searchData();
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      });
    },

    /** 状态修改 */
    handleShelf({ id }, status, text) {
      this.$moe_layer.confirm(`您确定要"${text}"当前选项吗？`, () => {
        this.$moe_api.GOODS_API.activityStatus({ id, status }).then((data) => {
          if (data.code === 200) {
            this.$moe_msg.success('操作成功');
            this.$refs['activityTable'].searchData();
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      })
    },
  },
};
</script>